import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import moment from "moment";

const app = createApp(App)
app.use(router)
app.use(store)

app.config.globalProperties.$filters = {
    formatDate(value, arg) {
        if (value) {
            return moment(String(value)).format(arg)
        }
    }
}

app.mount('#app')
