<template>
  <main class="container">
    <h1>Moderations Nachrichten</h1>
    <button class="btn btn-light" @click="sort = false" v-if="sort"><span class="bi-sort-numeric-up-alt"></span></button>
    <button class="btn btn-light" @click="sort = true" v-if="!sort"><span class="bi-sort-numeric-down"></span></button>
    <TextCard v-for="message of filteredMessages" :key="message.id" :message="message" :token="token" :stream="stream" :labels="[]"></TextCard>
  </main>
</template>

<script>

import websockets from '../assets/sockets'
import TextCard from "@/components/TextCard";

export default {
  name: 'Moderator',
  components: {
    TextCard
  },
  data() {
    return {
      messages: [],
      stream: "",
      token: "",
      sort: true
    }
  },
  computed: {
    filteredMessages() {
      let sort = this.sort
      return this.messages.slice(0).sort((a, b) => {
        let dateA = new Date(a.created_at).getTime()
        let dateB = new Date(b.created_at).getTime()
        if(sort) {
          if(dateA < dateB) {
            return 1
          } else if(dateA > dateB) {
            return -1
          } else {
            return 0
          }
        } else {
          if(dateA < dateB) {
            return -1
          } else if(dateA > dateB) {
            return 1
          } else {
            return 0
          }
        }
      }).filter(v => v.show_moderator)
    }
  },
  methods: {
    setMessages(msg) {
      this.messages = msg
    },
    reconnectHandler() {
      console.log("reconnecting")
      websockets.emit("connect", this.$route.params.id)
    },
    setLogo(msg) {
      this.$store.commit('setLogo', msg.logo_url)
    }
  },
  mounted() {
    websockets.on("messages", this.setMessages)
    websockets.on("logo", this.setLogo)
    websockets.emit("connect", this.$route.params.id)
    this.stream = this.$route.params.id
    this.token = this.$route.params.token
    websockets.onConnect(this.reconnectHandler)
  },
  beforeUnmount() {
    websockets.off(this.setMessages)
    websockets.off(this.setLogo)
    websockets.offConnect(this.reconnectHandler)
  }
}
</script>


<style scoped>
</style>
