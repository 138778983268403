let socket
let callbacks = {}
let connectHandlers = []
let disconnectHandlers = []
let toSend = []
let pingInterval;
let connectTimer;
let abortController = new AbortController()
function openSocket() {
    window.clearTimeout(connectTimer);
    connectTimer = undefined;
    let fetchTimeout = window.setTimeout(() => {
        abortController.abort()
    }, 5000)
    fetch(`${(window.location.protocol === "http:" ? "http:" : "https:")}//${window.location.host}/ws`, {signal: abortController.signal}).then(() => {
        window.clearTimeout(fetchTimeout)
        window.socket = socket = new WebSocket(`${(window.location.protocol === "http:" ? "ws:" : "wss:")}//${window.location.host}/ws`)
        socket.onopen = function() {
            console.log("connected to server")
            pingInterval = window.setInterval(ping, 5000)
            callConnect()
            while(toSend.length > 0) {
                let msg = toSend.splice(0,1)[0]
                socket.send(JSON.stringify(msg))
            }
        };
        socket.onmessage = function(e) {
            let msg;
            try {
                msg = JSON.parse(e.data)
            } catch(err) {
                console.log(err)
                console.log(e.data)
                return
            }

            if(callbacks[msg.type] && callbacks[msg.type].length > 0) {
                callbacks[msg.type].forEach((cb) => {
                    cb.apply(socket, [msg.args])
                })
            }
        }
        socket.onclose = function() {
            window.clearInterval(pingInterval)
            console.log("connection lost")
            callDisconnect()
            reconnect()
        }
        socket.onerror = function(event) {
            console.error("WebSocket error observed:", event);
        };
    }).catch((err) => {
        window.clearTimeout(fetchTimeout)
        console.log("fetch failed, restarting reconnect", err)
        reconnect()
    })
}
openSocket();

function reconnect() {
    if(connectTimer)
        return
    connectTimer = window.setTimeout(openSocket, 1000);
}

function ping() {
    if(socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({type: "ping"}))
    }

}
function on(eventName, callback) {
    if(!callbacks[eventName]) {
        callbacks[eventName] = []
    }
    callbacks[eventName].push(callback)
    return callback
}
function off(callback) {
    for(let i in callbacks) {
        let index = callbacks[i].indexOf(callback)
        if(index > -1) {
            callbacks[i].splice(index, 1)
        }
    }
}
function emit(eventName, data) {
    let msg = {
        type: eventName,
        args: data
    }
    if(socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(msg))
    } else {
        toSend.push(msg)
    }
}

function onConnect(callback) {
    connectHandlers.push(callback)
    return callback
}
function offConnect(callback) {
    let index = connectHandlers.indexOf(callback)
    if(index > -1) {
        connectHandlers.splice(index, 1)
    }
}
function callConnect() {
    connectHandlers.forEach((cb) => {
        cb.apply(socket)
    })
}
function onDisconnect(callback) {
    disconnectHandlers.push(callback)
    return callback
}
function offDisconnect(callback) {
    let index = disconnectHandlers.indexOf(callback)
    if(index > -1) {
        disconnectHandlers.splice(index, 1)
    }
}
function callDisconnect() {
    disconnectHandlers.forEach((cb) => {
        cb.apply(socket)
    })
}
export default {on, off, emit, onConnect, offConnect, onDisconnect, offDisconnect};