<template>
  <teleport to="#nav-container">
    <li class="nav-item"><a :href="`/api/${stream}/${token}/export`" class="nav-link" target="_blank">CSV Export</a></li>
    <li class="nav-item"><a href="#" class="nav-link" @click.prevent="removeAll()">Alles löschen</a></li>
  </teleport>
  <main class="container">
    <h1 class="text-center m-5">Moderations Nachrichten</h1>
    <div class="row">
      <div class="col-lg-4 justify-content-lg-center mb-4">
        Eine neue Nachricht verfassen
        <textarea v-model="text" placeholder="Text" class="form-control mt-2 mb-2" style="height: 200px"></textarea>
        <button @click="create()" class="btn btn-primary">Erstellen</button>
        <hr class="mb-5 mt-5"/>
        <h3>Getaggte Nachrichten der Moderationsansicht zeigen</h3>
        <div class="input-group">
          <select class="form-control" v-model.number="selectedLabel">
            <option value="0">-------</option>
            <option v-for="label of labels" :key="label.id" :value="label.id">{{ label.text }}</option>
          </select>
          <button class="btn btn-success" @click="showLabels()">Zeigen</button>
          <button class="btn btn-warning" @click="replaceLabels()">Ersetzen</button>
        </div>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-7">
        <div class="input-group mb-4">
          <button class="btn btn-light" @click="sort = false" v-if="sort"><span class="bi-sort-numeric-up-alt"></span></button>
          <button class="btn btn-light" @click="sort = true" v-if="!sort"><span class="bi-sort-numeric-down"></span></button>
          <select class="form-select" v-model.number="filteredLabel">
            <option value="0">-------</option>
            <option v-for="label of labels" :key="label.id" :value="label.id">{{ label.text }}</option>
          </select>
        </div>
        <TextCard v-for="message of filteredMessages" :key="message.id" :message="message" :token="token" :stream="stream" :labels="labels" :is-manager="true"></TextCard>
      </div>
    </div>
  </main>
</template>

<script>

import websockets from '../assets/sockets'
import axios from 'axios'
import TextCard from "@/components/TextCard";

export default {
  name: 'Manager',
  components: {TextCard},
  data() {
    return {
      messages: [],
      labels: [],
      text: "",
      stream: "",
      token: "",
      sort: true,
      selectedLabel: 0,
      filteredLabel: 0,
      errorcode: 200
    }
  },
  computed: {
    filteredMessages() {
      let sort = this.sort
      return this.messages.slice(0).sort((a, b) => {
        let dateA = new Date(a.created_at).getTime()
        let dateB = new Date(b.created_at).getTime()
        if(sort) {
          if(dateA < dateB) {
            return 1
          } else if(dateA > dateB) {
            return -1
          } else {
            return 0
          }
        } else {
          if(dateA < dateB) {
            return -1
          } else if(dateA > dateB) {
            return 1
          } else {
            return 0
          }
        }
      }).filter((e) => {
        if(this.filteredLabel) {
          if(e.label_id === this.filteredLabel)
            return true
          return false
        }
        return true
      })
    }
  },
  methods: {
    setMessages(msg) {
      this.messages = msg
    },
    create() {
      axios.post(`/api/${this.stream}/${this.token}/message`, this.text).then(() => {

      })
      this.text = ""
    },
    showLabels() {
      axios.post(`/api/${this.stream}/${this.token}/label/${this.selectedLabel}/show`).then(() => {

      })
      this.selectedLabel = 0
    },
    replaceLabels() {
      axios.post(`/api/${this.stream}/${this.token}/label/${this.selectedLabel}/replace`).then(() => {}).catch(err => {
        this.$store.commit("setErrorcode", err.response.status)
      })
      this.selectedLabel = 0
    },
    setLabels(msg) {
      this.labels = msg
    },
    createLabel(message, labelname) {
      let that = this
      axios.post(`/api/${this.stream}/${this.token}/label`, labelname).then(data => {
        that.updateMessage(message, "label_id", data.data.id)
        that.states[message.id] = '';
      })
    },
    removeAll() {
      if(!this.token || !this.stream) {
        return
      }
      axios.delete(`/api/${this.stream}/${this.token}/message`).then(() => {

      })
    },
    setLogo(msg) {
      this.$store.commit('setLogo', msg.logo_url)
    },
    reconnectHandler() {
      console.log("reconnecting")
      websockets.emit("connect", this.$route.params.id)
    }
  },
  mounted() {
    websockets.on("messages", this.setMessages)
    websockets.on("labels", this.setLabels)
    websockets.on("logo", this.setLogo)
    websockets.emit("connect", this.$route.params.id)
    this.stream = this.$route.params.id
    this.token = this.$route.params.token
    websockets.onConnect(this.reconnectHandler)
  },
  beforeUnmount() {
    websockets.off(this.setMessages)
    websockets.off(this.setLabels)
    websockets.off(this.setLogo)
    websockets.offConnect(this.reconnectHandler)
  }
}
</script>


<style scoped>
.pointer {
  cursor: pointer;
}
</style>
