<template>
  <main class="container">
    <h1 class="text-center m-5">Administration</h1>
    <div class="row">
      <div class="col-lg-4 justify-content-lg-center mb-4">
        <form @submit.prevent="createEvent()">
          <div class="mb-3">
            <label class="form-label">Event Name</label>
            <input type="text" class="form-control" v-model="newEventName" />
          </div>
          <div class="mb-3">
            <label class="form-label">Logo</label>
            <input type="file" class="form-control" @change="addLogo" />
          </div>
          <input type="submit" class="btn btn-primary" value="Anlegen" />
        </form>
      </div>
      <div class="col-lg-1"></div>
      <div class="col-lg-7">
        <h2>Angelegte Events</h2>
        <div v-for="event in events" :key="event.id" class="mb-5">
          <h3>{{ event.name }}</h3><br />
          <img :src="event.logo_url" v-if="event.logo_url" style="max-height: 24px"><br />
          <strong>Manager Link</strong><br />
          <a :href="`https://moderation.itcreates.media/manager/${event.id}/${event.token}`" target="_blank">https://moderation.itcreates.media/manager/{{event.id}}/{{event.token}}</a><br />
          <strong>Moderations Link</strong><br />
          <a :href="`https://moderation.itcreates.media/moderator/${event.id}/${event.token}`" target="_blank">https://moderation.itcreates.media/moderator/{{event.id}}/{{event.token}}</a><br />
          <strong>Screen Link</strong><br />
          <a :href="`https://moderation.itcreates.media/screen/${event.id}`" target="_blank">https://moderation.itcreates.media/screen/{{event.id}}</a><br />
          <button class="btn btn-danger" @click="deleteEvent(event.id)">Delete</button>
          <hr />
        </div>
      </div>
    </div>
  </main>
</template>

<script>

import axios from 'axios'

export default {
  name: 'Admin',
  data() {
    return {
      newEventName: "",
      events: [],
      logo: null,
    }
  },
  computed: {
  },
  methods: {
    createEvent() {
      let form = new FormData()
      if(this.logo) {
        form.append("logo", this.logo)
      }
      form.append("name", this.newEventName)
      axios.post(`/api/admin/${this.$route.params.token}/event`, form).then(data => {
        this.events.push(data.data)
      })
    },
    deleteEvent(id) {
      axios.delete(`/api/admin/${this.$route.params.token}/event/${id}`).then(() => {
        for(let i=0; i<this.events.length; i++) {
          if(this.events[i].id === id) {
            this.events.splice(i, 1);
          }
        }
      })
    },
    addLogo(event) {
      this.logo = event.target.files[0];
    }
  },
  mounted() {
    axios.get(`/api/admin/${this.$route.params.token}/event`).then(data => {
      this.events = data.data
    })
  }
}
</script>


<style scoped>
.pointer {
  cursor: pointer;
}
</style>
