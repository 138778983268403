<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid">
      <router-link class="navbar-brand logo" to="/" v-if="logoUrl"><img :src="logoUrl"></router-link>
      <router-link class="navbar-brand logo" to="/" v-if="!logoUrl"><img src="/logo.png"></router-link>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
              aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarsExampleDefault">
        <ul class="navbar-nav" id="nav-container">
        </ul>
        <span class="bi bi-wifi-off text-danger waitingForConnection" v-if="!connected"></span>
      </div>
    </div>
  </nav>
  <div id="alerts" class="container">
    <div class="alert alert-danger" role="alert" v-if="errorcode === 400">
      <span class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></span> Da stimmt was nicht! Versuchen Sie es nochmal richtig!
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorcode === 404">
      <span class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></span> Dieser Stream wurde nicht gefunden!
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorcode === 401">
      <span class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></span> Der Token in der URL ist falsch, Sie haben keine Schreibberechtigung!
    </div>
    <div class="alert alert-danger" role="alert" v-if="[200,400,401,404].indexOf(errorcode) === -1">
      <span class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></span> Ein interner Serverfehler ist aufgetreten, versuchen Sie es später erneut.
    </div>
  </div>
  <router-view></router-view>
</template>

<script>

import websockets from './assets/sockets'
import { mapState } from 'vuex'

export default {
  name: 'App',
  data() {
    return {
      connected: false
    }
  },
  computed: {
    logoUrl() {
      return this.$store.state.logoUrl
    },
    ...mapState(["errorcode"])
  },
  methods: {
    onConnect() {
      this.connected = true
    },
    onDisconnect() {
      this.connected = false
    }
  },
  mounted() {
    websockets.onConnect(this.onConnect)
    websockets.onDisconnect(this.onDisconnect)
    websockets.on("error", msg => {
      this.$store.commit("setErrorcode", msg.status)
    })
  }

}
</script>

<style lang="scss">
body, html {
  min-height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
}
.logo img {
  max-height: 24px;
}
@-webkit-keyframes blinker {
  from { opacity: 1.0; }
  to { opacity: 0.0; }
}

.waitingForConnection {
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(.5, 0, 1, 1);
  -webkit-animation-duration: 1.7s;
}
</style>
