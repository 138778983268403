<template>
  <div class="card text-dark bg-light mb-3">
    <div class="card-header d-flex justify-content-between">
      <span>{{ $filters.formatDate(message.created_at, 'HH:mm') }} Uhr {{ $filters.formatDate(message.created_at, 'DD. MMM YYYY') }}</span>
      <div v-if="message.label_id" class="align-self-center"><span class="badge bg-primary">{{ message.label.text }} <span class="bi-x pointer" @click="updateMessage(message, 'label_id', 0)" v-if="isManager"></span></span></div>
      <button class="btn btn-danger btn-sm" @click="removeMessage(message)" v-if="isManager"><span class="bi-trash-fill"></span></button>
    </div>
    <div class="card-body">
      <p class="card-text">
        {{ message.text}}
      </p>
    </div>
    <div class="card-footer d-flex justify-content-between">
      <div>
        <button class="btn btn-success m-1" v-if="message.show_moderator && isManager" @click="updateMessage(message, 'show_moderator', false)">
          Moderator anzeigen <i class="bi-hand-thumbs-up-fill"></i>
        </button>
        <button class="btn btn-danger m-1" v-if="!message.show_moderator && isManager" @click="updateMessage(message, 'show_moderator', true)">
          Moderator anzeigen <i class="bi-hand-thumbs-up-fill"></i>
        </button>
        <button class="btn btn-success m-1" v-if="message.show_screen" @click="updateMessage(message, 'show_screen', false)">
          Bildschirm anzeigen <i class="bi-display-fill"></i>
        </button>
        <button class="btn btn-danger m-1" v-if="!message.show_screen" @click="updateMessage(message, 'show_screen', true)">
          Bildschirm anzeigen <i class="bi-display-fill"></i>
        </button>
      </div>

      <div v-if="!message.label_id && isManager">
        <button class="btn btn-light" v-if="!state" @click="state = 'select'">Tag Hinzufügen</button>
        <div class="input-group" v-if="state === 'select'">
          <select class="form-control" @change="updateMessage(message, 'label_id', parseInt($event.target.value))">
            <option value="0">-------</option>
            <option v-for="label of labels" :key="label.id" :value="label.id">{{ label.text }}</option>
          </select>
          <button class="btn btn-light" @click="state = 'create'">+</button>
        </div>
        <div class="input-group" v-if="state === 'create'">
          <input type="text" class="form-control" placeholder="Labelname" v-model="labelname">
          <button class="btn btn-success" @click="createLabel(message)">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'TextCard',
  props: {
    message: Object,
    labels: Array,
    stream: String,
    token: String,
    isManager: Boolean
  },
  data() {
    return {
      state: "",
      labelname: ""
    }
  },
  methods: {
    updateMessage(message, element, value) {
      message[element] = value
      axios.put(`/api/${this.stream}/${this.token}/message/${message.id}`, message).then(() => {

      })
      this.state = ''
    },
    removeMessage(message) {
      axios.delete(`/api/${this.stream}/${this.token}/message/${message.id}`).then(() => {

      })
    },
    createLabel(message) {
      let that = this
      axios.post(`/api/${this.stream}/${this.token}/label`, this.labelname).then(data => {
        that.updateMessage(message, "label_id", data.data.id)
        that.state = '';
        that.labelname = '';
      })
    }
  }
}
</script>


<style scoped>
.pointer {
  cursor: pointer;
}
</style>
