<template>
  <main class="bg-dark text-light">
    <div :style="{'font-size': fontSize + 'px'}" class="hugetext">{{ message }}</div>
    <span ref="test" class="testElem hugetext"></span>
  </main>
</template>

<script>

import websockets from '../assets/sockets'

export default {
  name: 'Manager',
  data() {
    return {
      message: "",
      fontSize: 18,
      minFontSize: 10,
      maxFontSize: 350
    }
  },
  computed: {
  },
  methods: {
    setMessages(msg) {
      let f = msg.filter(e => e.show_screen)
      if(f.length) {
        this.message = f[0].text
        this.fitText()
      } else {
        this.message = ""
      }
    },
    toggleFullscreen() {
      let elem = document.documentElement;

      if (!document.fullscreenElement && !document.mozFullScreenElement &&
          !document.webkitFullscreenElement && !document.msFullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    fitText() {
      if(!this.message) {
        return
      }
      let maxHeight = document.documentElement.clientHeight - 80;
      let maxWidth = document.documentElement.clientWidth - 50;
      let elem = this.$refs.test
      elem.innerText = this.message;
      let low = this.minFontSize;
      let high = this.maxFontSize;
      let mid;
      while (low <= high) {
        mid = (high + low) >> 1;
        elem.style.fontSize = mid + 'px';
        if(elem.scrollWidth <= maxWidth && elem.scrollHeight <= maxHeight){
          low = mid + 1;
        } else {
          high = mid - 1;
        }
        // await injection point
      }
      this.fontSize = (high + low) >> 1
      elem.innerText = ""
    },
    handler(e) {
      switch(e.key) {
        case "+":
          this.fontSize += 2
          break
        case "-":
          this.fontSize -= 2
          break
        case "Enter":
          this.toggleFullscreen()
          break
      }
    },
    reconnectHandler() {
      console.log("reconnecting")
      websockets.emit("connect", this.$route.params.id)
    },
    setLogo(msg) {
      this.$store.commit('setLogo', msg.logo_url)
    }
  },
  mounted() {
    websockets.on("messages", this.setMessages)
    websockets.on("logo", this.setLogo)
    websockets.emit("connect", this.$route.params.id)
    this.stream = this.$route.params.id
    window.addEventListener('keydown', this.handler);
    window.addEventListener("resize", this.fitText);
    websockets.onConnect(this.reconnectHandler)
  },
  beforeUnmount() {
    websockets.off(this.setMessages)
    websockets.off(this.setLogo)
    window.removeEventListener('keydown', this.handler);
    websockets.offConnect(this.reconnectHandler)
  }
}
</script>


<style scoped>
main {
  padding: 0 25px;
  height: calc(100vh - 56px);
}
.testElem {
  opacity: 0;
  display: inline-block;
}
.hugetext {
  line-height: 1.2em;
  text-align: left;
}
</style>
