import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Manager from "@/views/Manager";
import Moderator from "@/views/Moderator";
import Screen from "@/views/Screen";
import Admin from "@/views/Admin";


const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/admin/:token',
        component: Admin
    },
    {
        path: '/manager/:id/:token',
        component: Manager
    },
    {
        path: '/moderator/:id/:token',
        component: Moderator
    },
    {
        path: '/screen/:id',
        component: Screen
    },
]

const router = new createRouter({
    history: createWebHistory(),
    base: '/',
    activeClass: 'active',
    routes
})

export default router