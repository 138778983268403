<template>
  <div>
    Um diesen Service nutzen zu können, benötigst du einen Token.
    Kontaktiere hendrik [at] hfellerhoff.de für mehr Informationen.
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
    }
  },
  computed: {
  },
}
</script>


<style scoped>
div {
  font-size: 2rem;
  text-align: center;
  margin-top: 100px;
}
</style>
